<template>
  <div class="p-3">
    <checkProfessionalMembershipCertificate
      :propsData1="getUserFromId"
      :propsData2="getProfessionalMembership"
    ></checkProfessionalMembershipCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import checkProfessionalMembershipCertificate from "../../components/checkProfessionalMembership/checkProfessionalMembershipCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckProfessionalMembershipCertificate",

  components: {
    checkProfessionalMembershipCertificate,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getProfessionalMembership", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchProfessionalMembership", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchProfessionalMembership(this.projectId);
  },
};
</script>
